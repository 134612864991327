import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/hero/FullWidthWithImage.js";
import Features from "components/features/ThreeColSimple.js";
import Services from "components/faqs/SimpleWithSideImage.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import tw from "twin.macro";
import { ContentWithVerticalPadding } from "components/misc/Layouts";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import Projects from "pages/BlogIndex.js";
import Managers from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";

const HeadingBase = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`;
const SectionHeading = tw(HeadingBase)`text-primary-900`;
const SectionContainer = tw(ContentWithVerticalPadding)``;
const SectionDescription = tw(
  DescriptionBase
)`text-justify mx-auto text-gray-600 max-w-6xl`;

function Home() {
  const [lang, setLang] = useState("se");

  return (
    <>
      <AnimationRevealPage>
        <Header langSwitch={(e) => setLang(e)} lang={lang} />
        <SectionContainer id="landingPageDemos">
          <SectionHeading style={{ color: "#666666" }}>
            {lang === "se" ? "Om FH Metal" : "About FH Metal"}
          </SectionHeading>
          <SectionDescription style={{ color: "#666666" }}>
            {lang === "se"
              ? `För att etablera management-, finans- och kvalitetssystem i alla delar av kundföretag har FH Metal Company förberett omfattande program med en konsult- och utbildningsinställning. I detta avseende marknadsförs målföretagen som är verksamma inom bygg- och infrastrukturprojekt. Baserat på undersökningar och erfarenheter från FH Metal-chefer har man i första steget övervägt tillgång till nya marknader för företaget, så att marknaderna i utvecklingsländer, särskilt västasiatiska länder, prioriteras av följande skäl:`
              : `In order to establish management, financial and quality systems in all parts of customer companies, FH Metal Company has prepared extensive programs with a consulting and training attitude. In this regard, the target companies active in the fields of construction and infrastructure projects are marketed. Based on the researches and experiences of FH Metal executives, in the first stage, access to new markets has been considered for the company, so the markets of developing countries, especially West Asian countries, are given priority for the following reasons:`}
            <ul>
              <li>
                {lang === "se" ? "1 - Stor befolkning" : "1- Large population"}
              </li>
              <li>
                {lang === "se"
                  ? "2- Dessa länder utvecklas och många infrastrukturprojekt pågår"
                  : `2- These countries are developing and many infrastructure
                projects are underway`}
              </li>
              <li>
                {lang === "se"
                  ? "3- Skillnaden i tid är cirka 2 timmar med Sverige"
                  : "3- The difference in time is about 2 hours with Sweden"}
              </li>
              <li>
                {lang === "se"
                  ? "4 - Förtroende för FH Metal-chefer med den aktuella geografin, ekonomin och kulturen i dessa länder"
                  : "4- Familiarity of FH Metal executives with the current geography, economy and culture of these countries"}
              </li>
            </ul>
            <p>
              {lang === "se"
                ? "Det har länge varit känt för alla ledningssamhällen i världen att förvaltningen av alla företag och genomförandet av stora projekt utan användning av uppdaterade och akademiska ledningssystem kommer att möta många problem som slösar bort tid, pengar."
                : "It has long been known to all management communities in the world that the management of any company and the completion of large projects without the use of up-to-date and academic management systems will face many problems that waste time, money."}
            </p>
            <p>
              {lang === "se"
                ? "Naturligtvis är konsultation och utbildning företagens lösningar för organisatorisk kompetens, och att driva företag framåt kommer att leda till utveckling av företagskraft och utgifter inom detta område, som en underliggande kostnad för alla företag, kommer att leda till långsiktig lönsamhet för dem."
                : "Naturally, consulting and training are companies' solutions for organizational excellence, and pushing companies forward will lead to the development of corporate manpower, and spending in this field, as an underlying cost for any company, will lead to long-term profitability for them."}
            </p>

            <p>
              {lang === "se"
                ? "Idag definieras konsult- och utbildningsbehov hos företag inom förvaltning, ekonomi och kvalitet som standardkurser och dess ämnen är tydliga. Med hjälp av dessa kurser kombinerar FH Metal Company konsult- och utbildningspaket med sin kunskap om lokala marknader. Definierar och implementerar för varje företag, vilket kommer att öka deras produktivitet och effektivitet."
                : "Today, the consulting and training needs of companies in the field of management, finance and quality are defined as standard courses and its topics are clear. Using these courses, FH Metal Company combines consulting and training packages with its knowledge of local markets. Defines and implements for each company, which will increase their productivity and efficiency."}
            </p>
            <p>
              {lang === "se"
                ? `På en konkurrenskraftig marknad inför företag är investeringar i nya marknader oundviklig, en fråga som starkt drivs av alla länder med vetenskap och teknik. Närvaro på sådana nya marknader skapar anslutningar för närvaro av andra svenska företag och uppfyller också andra deras behov och krav kommer från Sverige, vilket är en viktig och värdefull sidofördel för FH Metal.
                I nästa steg är att utvidga och fördjupa de nödvändiga anslutningarna för att ingå större kontrakt på nya marknader, samt investera och delta i den svenska bostadsmarknaden, några av företagets strategier för framtiden för FH Metal.
                `
                : `In a competitive market facing companies, investing in new markets is inevitable, an issue that is strongly pursued by all countries with science and technology. Presence in such new markets creates connections for the presence of other Swedish companies and also meets other their needs and requirements come from Sweden, which is an important and valuable side benefit for FH Metal.
                In the next step, expanding and deepening the necessary connections for concluding larger contracts in new markets, as well as investing and participating in the Swedish housing market, is some of the company's strategies for the future of FH Metal.
                `}
            </p>
          </SectionDescription>
        </SectionContainer>
        <Features lang={lang} />
        <Managers lang={lang} />
        <Services lang={lang} />
        <Projects lang={lang} />
        <Footer lang={lang} />
      </AnimationRevealPage>
    </>
  );
}

export default Home;
