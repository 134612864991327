import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import {
  Container,
  ContentWithVerticalPadding,
} from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg";
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-xl`;
const Heading = tw(
  SectionHeading
)`text-left text-primary-900 leading-snug xl:text-2xl`;

const HeadingName = tw(
  SectionHeading
)`text-left text-primary-900 leading-snug xl:text-4xl py-3`;

const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;

const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;

export default ({ lang, imageDecoratorBlob = true }) => {
  const cards = [
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/mng2.jpg",
      quote:
        "Twenty years consultant for construction and infrastructure projects, financial consultant (with a regional trade approach, especially in Iraq and Kurdistan), expert in geography of the West Asian region, consultant to international companies in obtaining projects",
      quote_se:
        "Tjugo årskonsult för bygg- och infrastrukturprojekt, finansiell konsult (med en regional handelstrategi, särskilt i Irak och Kurdistan), expert på geografi i den västra asiatiska regionen, konsult till internationella företag för att få projekt",
      customerName: "Mr. Hassan Hormati",
      customerTitle: "Chief Executive Officer",
      customerTitle_se: "Verkställande direktör",
      phone: "+46 729318593",
      email: "h.hormati1965@yahoo.com",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/tahmasebi.jpg",
      quote:
        "Master of Total Quality, Quality Systems Instructor, Twenty Years of Experience in Quality Projects Implementation, Project Feasibility Consultant, Financial Systems and Budget Consulting, Quality Standards Instructor, Human Resources Consultant, Expert in Defining Supervision Plans, Organizational Chart Designer, MSA Specialist, Problem Solving Systems Consultant, Cost Reduction Consultant, Statistical Analysis Expert",
      quote_se:
        "Master i total kvalitet, instruktör för kvalitetssystem, tjugo års erfarenhet av genomförande av kvalitetsprojekt, genomförbarhetskonsult för projekt, finansiella system och budgetkonsulting, instruktör för kvalitetsstandarder, personalkonsult, expert i att definiera övervakningsplaner, organisationsdiagramdesigner, MSA-specialist, problem Lösning av systemkonsult, kostnadsminskningskonsult, statistisk analysekspert",
      customerName: "Mr. Alireza Tahmasebi",
      customerTitle: "Expert",
      customerTitle_se: "Expert",
      phone: "+46 735787605",
      email: "alireza.tahmasebi1355@yahoo.com",
    },
  ];
  return (
    <>
      <Container>
        <HeadingInfoContainer>
          <HeadingTitle style={{ color: "#666666" }}>
            {lang === "se" ? "Företagsledare" : "Corporate Managers"}
          </HeadingTitle>
        </HeadingInfoContainer>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <HeadingName style={{ color: "#666666" }}>
                {lang === "se" ? cards[0].customerName : cards[0].customerName}
              </HeadingName>
              <Heading style={{ color: "#ff6666" }}>
                {lang === "se"
                  ? cards[0].customerTitle_se
                  : cards[0].customerTitle}
              </Heading>
              <Description style={{ color: "#666666" }}>
                {lang === "se" ? cards[0].quote_se : cards[0].quote}
              </Description>
              <div style={{ display: "flex", padding: "16px 0px" }}>
                <img
                  src="http://fhmetal.se/image/mail.png"
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />
                <p
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingLeft: "10px",
                    fontWeight: "700",
                    color: "#666666",
                  }}
                >
                  {cards[0].email}
                </p>
              </div>
              <div style={{ display: "flex", padding: "16px 0px" }}>
                <img
                  src="http://fhmetal.se/image/phone.png"
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />
                <p
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingLeft: "10px",
                    fontWeight: "700",
                    color: "#666666",
                  }}
                >
                  {cards[0].phone}
                </p>
              </div>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={cards[0].imageSrc} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
          <Row>
            <ImageColumn>
              <Offsetbackground />
              <ImageContainer>
                {imageDecoratorBlob && <ImageDecoratorBlob />}
                <Image src={cards[1].imageSrc} />
              </ImageContainer>
            </ImageColumn>
            <TextColumn>
              <HeadingName style={{ color: "#666666" }}>
                {lang === "se" ? cards[1].customerName : cards[1].customerName}
              </HeadingName>
              <Heading style={{ color: "#ff6666" }}>
                {lang === "se"
                  ? cards[1].customerTitle_se
                  : cards[1].customerTitle}
              </Heading>
              <Description style={{ color: "#666666" }}>
                {lang === "se" ? cards[1].quote_se : cards[1].quote}
              </Description>
              <div style={{ display: "flex", padding: "16px 0px" }}>
                <img
                  src="http://fhmetal.se/image/mail.png"
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />
                <p
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingLeft: "10px",
                    fontWeight: "700",
                    color: "#666666",
                  }}
                >
                  {cards[1].email}
                </p>
              </div>
              <div style={{ display: "flex", padding: "16px 0px" }}>
                <img
                  src="http://fhmetal.se/image/phone.png"
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />
                <p
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingLeft: "10px",
                    fontWeight: "700",
                    color: "#666666",
                  }}
                >
                  {cards[1].phone}
                </p>
              </div>
            </TextColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
