import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingLg } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-6xl mx-auto`;
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/2 flex flex-col items-center`;
const CardImage = styled.div`
  ${(props) => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-80 h-80 bg-cover bg-center rounded`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`;

const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose text-left p-5`;

export default ({
  lang,
  heading = "Meet These Fine Folks.",
  cards = [
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/mng4.jpg",
      quote:
        "Twenty years consultant for construction and infrastructure projects, financial consultant (with a regional trade approach, especially in Iraq and Kurdistan), expert in geography of the West Asian region, consultant to international companies in obtaining projects",
      quote_se:
        "Tjugo årskonsult för bygg- och infrastrukturprojekt, finansiell konsult (med en regional handelstrategi, särskilt i Irak och Kurdistan), expert på geografi i den västra asiatiska regionen, konsult till internationella företag för att få projekt",
      name: "Mr. Hassan Hormati",
      position: "Chief Executive Officer",
      position_se: "Verkställande direktör",
      phone: "+46 729318593",
      email: "h.hormati1965@yahoo.com",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/mng5.jpg",
      quote:
        "Master of Total Quality, Quality Systems Instructor, Twenty Years of Experience in Quality Projects Implementation, Project Feasibility Consultant, Financial Systems and Budget Consulting, Quality Standards Instructor, Human Resources Consultant, Expert in Defining Supervision Plans, Organizational Chart Designer, MSA Specialist, Problem Solving Systems Consultant, Cost Reduction Consultant, Statistical Analysis Expert",
      quote_se:
        "Master i total kvalitet, instruktör för kvalitetssystem, tjugo års erfarenhet av genomförande av kvalitetsprojekt, genomförbarhetskonsult för projekt, finansiella system och budgetkonsulting, instruktör för kvalitetsstandarder, personalkonsult, expert i att definiera övervakningsplaner, organisationsdiagramdesigner, MSA-specialist, problem Lösning av systemkonsult, kostnadsminskningskonsult, statistisk analysekspert",
      name: "Mr. Alireza Tahmasebi",
      position: "Expert",
      position_se: "Expert",
      phone: "+46 735787605",
      email: "alireza.tahmasebi1355@yahoo.com",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingLg>
        <HeadingContainer>
          <Heading style={{ color: "#666666" }}>
            {lang === "se" ? "Företagsledare" : "Corporate Managers"}
          </Heading>
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position" style={{ color: "#ff6666" }}>
                  {lang === "se" ? card.position_se : card.position}
                </span>
                <span className="name" style={{ color: "#666666" }}>
                  {card.name}
                </span>
                <Quote style={{ height: "270px", color: "#666666" }}>
                  "{lang === "se" ? card.quote_se : card.quote}"
                </Quote>
                <div
                  style={{
                    display: "flex",
                    padding: "16px",
                    width: "100%",
                  }}
                >
                  <img
                    src="http://fhmetal.se/image/mail.png"
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                  <p
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      paddingLeft: "10px",
                      fontWeight: "700",
                      color: "#666666",
                    }}
                  >
                    {card.email}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "16px",
                    width: "100%",
                  }}
                >
                  <img
                    src="http://fhmetal.se/image/phone.png"
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                  <p
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      paddingLeft: "10px",
                      fontWeight: "700",
                      color: "#666666",
                    }}
                  >
                    {card.phone}
                  </p>
                </div>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingLg>
    </Container>
  );
};
