import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${(props) => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase  text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-red-500 after:w-8`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:transition duration-300`;
const Description = tw.div``;

export const PrimaryButton = tw.button`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;
export default ({
  lang,
  posts = [
    {
      imageSrc: "http://fhmetal.se/image/pr3.jpeg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
      featured: true,
    },
    {
      imageSrc: "http://fhmetal.se/image/pr2.jpeg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/pr1.jpeg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/pr6.jpeg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/pr12.jpeg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr1.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr2.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr10.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr133.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr18.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr22.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr25.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr31.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr32.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr33.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr34.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr36.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr37.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr388.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/pr123.jpg",
      category: "Sample Project",
      category_se: "Exempelprojekt",
      title: "Sample project of companies under our contract",
      title_se: "Exempel på projekt av företag enligt vårt kontrakt",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/pr15.jpg",
      category: "session",
      category_se: "session",
      title: "Counseling and training session",
      title_se: "Rådgivning och träning",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/pr17.jpg",
      category: "session",
      category_se: "session",
      title: "Counseling and training session",
      title_se: "Rådgivning och träning",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/pr19.jpg",
      category: "session",
      category_se: "session",
      title: "Counseling and training session",
      title_se: "Rådgivning och träning",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/pr20.jpg",
      category: "session",
      category_se: "session",
      title: "Counseling and training session",
      title_se: "Rådgivning och träning",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/pr21.jpg",
      category: "session",
      category_se: "session",
      title: "Counseling and training session",
      title_se: "Rådgivning och träning",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/pr29.jpg",
      category: "session",
      category_se: "session",
      title: "Counseling and training session",
      title_se: "Rådgivning och träning",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/pr40.jpg",
      category: "session",
      category_se: "session",
      title: "Counseling and training session",
      title_se: "Rådgivning och träning",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/pr8.jpg",
      category: "session",
      category_se: "session",
      title: "Counseling and training session",
      title_se: "Rådgivning och träning",
    },
  ],
}) => {
  const [visible, setVisible] = useState(10);
  const onLoadMoreClick = () => {
    setVisible((v) => v + 9);
  };
  return (
    <AnimationRevealPage>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>
              {lang === "se"
                ? "Vi har gjort några fantastiska projekt"
                : `We've done some amazing projects`}
            </Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" style={{ cursor: "default" }}>
                  <Image imageSrc={post.imageSrc} />
                  <Info style={{ minHeight: "204px" }}>
                    <Category style={{ color: "#ff6666" }}>
                      {lang === "se" ? post.category_se : post.category}
                    </Category>
                    <Title>{lang === "se" ? post.title_se : post.title}</Title>
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton
                onClick={onLoadMoreClick}
                style={{
                  background:
                    "linear-gradient(331deg, rgba(255,160,102,1) 56%, rgba(255,102,102,1) 100%)",
                }}
              >
                Load More Projects
              </LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
