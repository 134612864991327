import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import Header, { LogoLink } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;
const Container = tw.div`relative -mx-8 -mt-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8 lg:w-5/12`;
const RightColumn = styled.div`
  background-image: url("http://fhmetal.se/image/biz.png");
  ${tw` bg-cover bg-center xl:ml-24 h-96 lg:h-auto lg:w-7/12 lg:flex-1`}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose`;

export default (props) => {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <StyledHeader
            lang={props.lang}
            langSwitch={(e) => props.langSwitch(e)}
            collapseBreakpointClass="sm"
          />
          <Content>
            <Heading style={{ color: "#666666" }}>
              {props.lang === "se"
                ? "Ta det ett steg närmare"
                : "Take it one step closer"}
              <wbr />
              <br />
              <span tw="text-primary-500">
                {props.lang === "se" ? "att lyckas" : "to success"}
              </span>
            </Heading>
            <Paragraph style={{ color: "#666666" }}>
              {props.lang === "se"
                ? `Vi är här för att påskynda uppnåendet av dina mål med våra
                professionellt konsultteam`
                : `We are here to accelerate the achievement of your goals, with our
              professional consulting team`}
            </Paragraph>
          </Content>
        </LeftColumn>
        <RightColumn />
      </TwoColumn>
    </Container>
  );
};
