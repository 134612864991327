import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-2-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;

const TestimonialSliderContainer = tw.div`mt-24`;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded`}
  }
`;
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-xl xl:text-xl`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-sm`;

const QuotesLeft = tw(
  QuotesLeftIcon
)`w-8 h-8 lg:w-8 lg:h-10 text-primary-500 absolute top-0 left-0`;
const QuotesRight = tw(
  QuotesRightIcon
)`w-8 h-8 lg:w-8 lg:h-10 text-primary-500 absolute bottom-0 right-0`;

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0">
    <button {...props} style={{ color: "#ff6666" }}>
      <ArrowRightIcon style={{ color: "#ff6666" }} />
    </button>
  </SliderControlButtonContainer>
);
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0">
    <button {...props} style={{ color: "#ff6666" }}>
      <ArrowLeftIcon style={{ color: "#ff6666" }} />
    </button>
  </SliderControlButtonContainer>
);

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default ({ lang }) => {
  const testimonials = [
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/mng2.jpg",
      quote:
        "Twenty years consultant for construction and infrastructure projects, financial consultant (with a regional trade approach, especially in Iraq and Kurdistan), expert in geography of the West Asian region, consultant to international companies in obtaining projects",
      quote_se:
        "Tjugo årskonsult för bygg- och infrastrukturprojekt, finansiell konsult (med en regional handelstrategi, särskilt i Irak och Kurdistan), expert på geografi i den västra asiatiska regionen, konsult till internationella företag för att få projekt",
      customerName: "Mr. Hassan Hormati",
      customerTitle: "Chief Executive Officer",
      customerTitle_se: "Verkställande direktör",
      phone: "+46 729318593",
      email: "h.hormati1965@yahoo.com",
    },
    {
      imageSrc: "http://fhmetal.se/image/projects/sessions/mng1.jpg",
      quote:
        "Master of Total Quality, Quality Systems Instructor, Twenty Years of Experience in Quality Projects Implementation, Project Feasibility Consultant, Financial Systems and Budget Consulting, Quality Standards Instructor, Human Resources Consultant, Expert in Defining Supervision Plans, Organizational Chart Designer, MSA Specialist, Problem Solving Systems Consultant, Cost Reduction Consultant, Statistical Analysis Expert",
      quote_se:
        "Master i total kvalitet, instruktör för kvalitetssystem, tjugo års erfarenhet av genomförande av kvalitetsprojekt, genomförbarhetskonsult för projekt, finansiella system och budgetkonsulting, instruktör för kvalitetsstandarder, personalkonsult, expert i att definiera övervakningsplaner, organisationsdiagramdesigner, MSA-specialist, problem Lösning av systemkonsult, kostnadsminskningskonsult, statistisk analysekspert",
      customerName: "Mr. Alireza Tahmasebi",
      customerTitle: "Expert",
      customerTitle_se: "Expert",
      phone: "+46 735787605",
      email: "alireza.tahmasebi1355@yahoo.com",
    },
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle style={{ color: "#666666" }}>
            {lang === "se" ? "Företagsledare" : "Corporate Managers"}
          </HeadingTitle>
        </HeadingInfoContainer>
        <TestimonialSliderContainer>
          <TestimonialSlider
            nextArrow={<NextArrow />}
            prevArrow={<PreviousArrow />}
          >
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>
                <ImageContainer>
                  <img
                    src={testimonial.imageSrc}
                    alt={testimonial.customerName}
                  />
                </ImageContainer>
                <TextContainer>
                  <QuoteContainer>
                    <QuotesLeft style={{ color: "#ff6666" }} />
                    <Quote style={{ color: "#666666" }}>
                      {lang === "se" ? testimonial.quote_se : testimonial.quote}
                    </Quote>
                    <QuotesRight style={{ color: "#ff6666" }} />
                  </QuoteContainer>
                  <div style={{ display: "flex", padding: "16px 40px" }}>
                    <img
                      src="http://fhmetal.se/image/mail.png"
                      alt=""
                      style={{ width: "40px", height: "40px" }}
                    />
                    <p
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        paddingLeft: "10px",
                        fontWeight: "700",
                        color: "#666666",
                      }}
                    >
                      {testimonial.email}
                    </p>
                  </div>
                  <div style={{ display: "flex", padding: "16px 40px" }}>
                    <img
                      src="http://fhmetal.se/image/phone.png"
                      alt=""
                      style={{ width: "40px", height: "40px" }}
                    />
                    <p
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        paddingLeft: "10px",
                        fontWeight: "700",
                        color: "#666666",
                      }}
                    >
                      {testimonial.phone}
                    </p>
                  </div>
                  <CustomerInfo>
                    <CustomerName style={{ color: "#ff6666" }}>
                      {testimonial.customerName}
                    </CustomerName>
                    <CustomerTitle style={{ color: "#666666" }}>
                      {lang === "se"
                        ? testimonial.customerTitle_se
                        : testimonial.customerTitle}
                    </CustomerTitle>
                  </CustomerInfo>
                </TextContainer>
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
      </Content>
      <DecoratorBlob1 style={{ color: "#ff6666" }} />
      <DecoratorBlob2 />
    </Container>
  );
};
