import React from "react";
import tw from "twin.macro";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent cursor-default`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-56`;

const CopyrightText = tw.p`font-medium pt-2 text-sm text-gray-600`;

export default ({ lang }) => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src="http://fhmetal.se/image/mainlogo.png" />
          </LogoContainer>
          <CopyrightText>
            &copy; {lang === "se" ? "upphovsrätt" : "Copyright"} 2020, FH Metal
            Inc.
            {lang === "en"
              ? " All Rights Reserved."
              : " Alla rättigheter förbehållna."}
          </CopyrightText>
        </WideColumn>
        <Column>
          <ColumnHeading style={{ color: "#666666" }}>
            {lang === "se" ? "Adress :" : "Address :"}
          </ColumnHeading>
          <LinkList style={{ color: "#666666" }}>
            <LinkListItem>
              <Link>Gustaf Dalénsgatan 11</Link>
            </LinkListItem>
            <LinkListItem>
              <Link>417 05 Gothenburg</Link>
            </LinkListItem>
            <LinkListItem>
              <Link>Sweden</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading style={{ color: "#666666" }}>
            {lang === "se" ? "Telefon :" : "Phone :"}
          </ColumnHeading>
          <LinkList style={{ color: "#666666" }}>
            <LinkListItem>
              <Link>+46 735787605</Link>
            </LinkListItem>
            <LinkListItem>
              <Link>+46 729318593</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading style={{ color: "#666666" }}>
            {lang === "se" ? "E-post :" : "Email :"}
          </ColumnHeading>
          <LinkList style={{ color: "#666666" }}>
            <LinkListItem>
              <Link>fhmetal@yahoo.com</Link>
            </LinkListItem>
            <LinkListItem>
              <Link>h.hormati1965@yahoo.com</Link>
            </LinkListItem>
            <LinkListItem>
              <Link>alireza.tahmasebi1355@yahoo.com</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
