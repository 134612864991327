import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-56 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export const DesktopLang = tw.nav`
   lg:flex flex-1 justify-end items-end
`;

export default ({
  logoLink,
  className,
  collapseBreakpointClass = "lg",
  langSwitch,
  lang,
}) => {
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={"http://fhmetal.se/image/mainlogo.png"} alt="logo" />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
      </DesktopNavLinks>
      <DesktopLang css={collapseBreakpointCss.desktopNavLinks}>
        <div className="chooseLang">
          <div
            className="change-lang-section d-flex"
            style={{ display: "flex" }}
          >
            <img
              src="http://fhmetal.se/image/uk-flag.png"
              alt="logo"
              onClick={() => langSwitch("en")}
              className="lang-logo"
              style={{
                // width: lang === "en" ? "30px" : "24px",
                height: lang === "en" ? "30px" : "24px",
                opacity: lang === "en" ? "1" : "0.5",
                marginTop: "auto",
                marginRight: "5px",
              }}
            />
            <img
              src="http://fhmetal.se/image/se-flag (2).png"
              alt="logo"
              onClick={() => langSwitch("se")}
              className="lang-logo"
              style={{
                // width: lang === "se" ? "30px" : "24px",
                height: lang === "se" ? "30px" : "24px",
                opacity: lang === "se" ? "1" : "0.5",
                marginTop: "auto",
                marginLeft: "5px",
              }}
            />
          </div>
        </div>
      </DesktopLang>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
