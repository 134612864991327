import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/hero/FullWidthWithImage.js";
import Features from "components/features/ThreeColSimple.js";
import Certificates from "components/blogs/ThreeColSimpleWithImage.js";
import Services from "components/faqs/SimpleWithSideImage.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import tw from "twin.macro";
import { ContentWithVerticalPadding } from "components/misc/Layouts";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import Managers from "components/testimonials/TwoColumnWithImage.js";
import Projects from "pages/BlogIndex.js";

const HeadingBase = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`;
const SectionHeading = tw(HeadingBase)`text-primary-900`;
const SectionContainer = tw(ContentWithVerticalPadding)``;
const SectionDescription = tw(
  DescriptionBase
)`text-center mx-auto text-gray-600 max-w-4xl`;

function Home() {
  const [lang, setLang] = useState("se");

  return (
    <>
      <AnimationRevealPage>
        <Header langSwitch={(e) => setLang(e)} lang={lang} />
        <SectionContainer id="landingPageDemos">
          <SectionHeading style={{ color: "#666666" }}>About Us</SectionHeading>
          <SectionDescription style={{ color: "#666666" }}>
            FH Metal Company is operating in developing countries by pursuing
            intermediate-term objectives such as consulting and educational
            services as well as investing in small- and large-scale construction
            projects including construction of buildings, repair and renewal of
            old buildings, it is also operating in European Union Member States,
            particularly, Sweden, pursuing long-term objectives. The most
            important objectives of this company for taking such activities is
            to hold training and consulting course for providing strategical
            services for construction, investing, and contracting companies. We
            could conclude several contracts with the foreign countries in this
            regard, and by considering our experience on this area, we will
            continue and develop our activities on investments in Sweden.
          </SectionDescription>
        </SectionContainer>
        <Features lang={lang} />
        <Managers lang={lang} />
        <Services lang={lang} />
        <Projects lang={lang} />
        <Certificates lang={lang} />
        <Footer lang={lang} />
      </AnimationRevealPage>
    </>
  );
}

export default Home;
