import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-3.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";

const HeadingContainer = tw.div`text-center`;
const Heading = tw(SectionHeading)``;

const Posts = tw.div`mt-12 flex flex-wrap -mr-3 relative`;
const Post = tw.a`flex flex-col h-full bg-gray-200 rounded`;
const PostImage = styled.div`
  ${(props) => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 sm:h-80 bg-center bg-cover rounded-t`}
`;
const PostText = tw.div`flex-1 px-6 py-8`;
const PostTitle = tw.h6`font-bold group-hocus:text-primary-500 transition duration-300 `;
const PostDescription = tw.p``;
const AuthorInfo = tw.div`flex`;
const AuthorName = tw.div`font-semibold mt-2`;

const PostContainer = styled.div`
  ${tw`relative z-20 mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full sm:w-full lg:w-2/3`}
      ${Post} {
        ${tw`sm:flex-row items-center sm:pr-3`}
      }
      ${PostImage} {
        ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-t sm:rounded-t-none sm:rounded-l`}
      }
      ${PostText} {
        ${tw`pl-8 pr-5`}
      }
      ${PostTitle} {
        ${tw`text-2xl`}
      }
      ${PostDescription} {
        ${tw`mt-4 text-sm font-semibold text-gray-600 leading-relaxed`}
      }
      ${AuthorInfo} {
        ${tw`mt-8 flex items-center`}
      }
      ${AuthorName} {
        ${tw`mt-0 font-bold text-gray-700 text-sm`}
      }
    `}
`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob2
)`absolute bottom-0 left-0 w-56 h-56 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-0 right-0 w-56 h-56 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`;

export default ({ lang }) => {
  const blogPosts = [
    {
      imageSrc: "http://fhmetal.se/image/cer1.jpeg",
    },
    {
      imageSrc: "http://fhmetal.se/image/cer2.jpeg",
    },
    {
      imageSrc: "http://fhmetal.se/image/cer3.jpeg",
    },
    {
      imageSrc: "http://fhmetal.se/image/cer4.jpeg",
    },
    {
      imageSrc: "http://fhmetal.se/image/cer5.jpeg",
    },
    {
      imageSrc: "http://fhmetal.se/image/cer6.jpeg",
    },
    {
      imageSrc: "http://fhmetal.se/image/cer7.jpeg",
    },
    {
      imageSrc: "http://fhmetal.se/image/cer8.jpeg",
    },
    {
      imageSrc: "http://fhmetal.se/image/cer9.jpeg",
    },
  ];
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          <Heading style={{ color: "#666666" }}>
            {lang === "se" ? "Våra certifikat" : "Our Certificates"}
          </Heading>
        </HeadingContainer>
        <Posts>
          {blogPosts.map((post, index) => (
            <PostContainer key={index}>
              <Post className="group ">
                <PostImage imageSrc={post.imageSrc} />
              </Post>
            </PostContainer>
          ))}
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </Posts>
      </ContentWithPaddingXl>
    </Container>
  );
};
