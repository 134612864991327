import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-5 lg:py-5`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const FAQContent = tw.div`lg:ml-12`;
const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion.custom(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion.custom(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

export default ({
  lang,
  imageSrc = "http://fhmetal.se/image/6573.jpg",
  faqs = null,
}) => {
  const faqs1 = [
    {
      question: "Business consultancy",
      question_se: "Affärsrådgivning",
      answer:
        "Business consultants provide management consulting to help organizations improve their performance and efficiency. These professionals analyze businesses and create solutions while also helping companies meet their goals. Business owners should consider hiring business consultants when they need help or perspective on their chosen path or a catalyst for change in their companies.",
      answer_se:
        "Företagskonsulter tillhandahåller managementkonsulting för att hjälpa organisationer att förbättra deras prestanda och effektivitet. Dessa yrkesgrupper analyserar företag och skapar lösningar samtidigt som de hjälper företag att uppfylla sina mål. Företagare bör överväga att anställa företagskonsulter när de behöver hjälp eller perspektiv på sin valda väg eller en katalysator för förändring i sina företag.",
    },
    {
      question: "Financial management",
      question_se: "Finanshantering",
      answer: `Financial management refers to all of the functions and aspects of the financial components of a company or for an individual. This includes, but is not limited to, the planning, managing, controlling, and evaluating of the finances and economic structure for a client.
      The financial management of a company requires putting together a strategy to ensure that a company maintains positive growth in its finances in order to maximise profit and minimise costs.
      Financial consultants may work for a firm or as a self-employed contractor, and their clients may be companies or individuals.
      In short, financial consultants offer personalized advice to help investors build wealth. They may offer financial planning, identify well-suited investments and guide insurance decisions. They often direct the buying and selling of investments, like stocks and bonds, on their clients’ behalf. Some may also sell financial products.`,
      answer_se: `Finansiell förvaltning avser alla funktioner och aspekter av de ekonomiska komponenterna i ett företag eller för en individ. Detta inkluderar, men är inte begränsat till, planering, hantering, kontroll och utvärdering av ekonomins och ekonomiska strukturen för en klient .
      Företags ekonomiska ledning kräver att man sätter ihop en strategi för att säkerställa att ett företag håller en positiv tillväxt i sina finanser för att maximera vinsten och minimera kostnaderna.
      Finansiella konsulter kan arbeta för ett företag eller som en egenföretagare och deras kunder kan vara företag eller enskilda.
      Kort sagt, finansiella konsulter erbjuder personlig rådgivning för att hjälpa investerare att bygga förmögenhet. De kan erbjuda ekonomisk planering, identifiera väl anpassade investeringar och vägleda försäkringsbeslut. De riktar ofta köp och försäljning av investeringar, som aktier och obligationer, för deras kunders räkning. Vissa kan också sälja finansiella produkter.`,
    },
    {
      question: "Organisational Management",
      question_se: "Organisationsledning",
      answer: `Organisational management involves the coordination and facilitation of organisational processes to accomplish core organisational goals. The primary aim of this course is to familiarise companies with some of the key topics in the domain of organisational management, and to train them in applying this knowledge to organisational practice. These key topics include human resource management, personnel selection, effective communication with both internal (e.g., employees) and external (e.g., clients) stakeholders, managing the organisation’s identity, corporate social responsibility, and corporate restructures such as mergers and strategic alliances. These topics will be approached by integrating conceptual, empirical and case methods`,
      answer_se:
        "Organisatorisk ledning involverar samordning och underlättande av organisatoriska processer för att uppnå kärnorganisatoriska mål. Det huvudsakliga syftet med denna kurs är att bekanta företag med några av de viktigaste ämnena inom organisationsledningsområdet och att utbilda dem i att tillämpa denna kunskap i organisationspraxis. Dessa viktiga ämnen inkluderar personalhantering, personalval, effektiv kommunikation med både interna (t.ex. anställda) och externa (t.ex. klienter) intressenter, hantering av organisationens identitet, företagens sociala ansvar och företagsomstrukturer som fusioner och strategiska allianser. Dessa ämnen kommer att behandlas genom att integrera konceptuella, empiriska och fallmetoder.",
    },
    {
      question: "Risk Management",
      question_se: "Riskhantering",
      answer: `In order to avoid risks or to at least be able to counteract them, it is vital that they are recognised, classified and evaluated. Tiered hierarchies, complex processes and IT systems, and international integration make risk management consulting services necessary to help secure the future of a company.
      Depending on the legal form, management is also legally obliged to operate a fully functional risk management and information system.
      Credit institutions and insurance companies also assess their customers on the basis of non-payment and liability risks. This is reflected in the interest rates offered for loans and insurance premiums. In many cases, customers do not discover how these are calculated. However, there are in fact often objective reasons that can be overcome. In order to be able to manage potential risks, you have to be aware of them so that you can then manage the company over the long term with low interest rates and insurance premiums`,
      answer_se: `För att undvika risker eller åtminstone kunna motverka dem är det mycket viktigt att de erkänns, klassificeras och utvärderas. Riktade hierarkier, komplexa processer och IT-system och internationell integration gör riskhanteringskonsulttjänster nödvändiga för att säkerställa företagets framtid.
      Beroende på den juridiska formen är förvaltningen också juridiskt skyldig att driva ett fullt fungerande riskhanterings- och informationssystem.
      Kreditinstitut och försäkringsbolag bedömer också sina kunder utifrån icke-betalnings- och ansvarsrisker. Detta återspeglas i de räntor som erbjuds för lån och försäkringspremier. I många fall upptäcker inte kunderna hur dessa beräknas. Men det finns faktiskt ofta objektiva skäl som kan övervinnas. För att kunna hantera potentiella risker måste du vara medveten om dem så att du sedan kan hantera företaget på lång sikt med låga räntor och försäkringspremier`,
    },
    {
      question: "IT Management Consulting",
      question_se: "IT Management Consulting",
      answer: `Information technology management (for short: IT management) deals with the control of information and data processing, as well as the hardware and software required for this. The ERP system represents one component of a comprehensive IT architecture.
      Strategic IT management is extremely valuable for company management because it directly affects the success of the business by increasing the value contributed by IT to the success of the company, while simultaneously minimising the risks and costs associated with IT.`,
      answer_se: `Management av informationsteknologi (kort sagt: IT-hantering) handlar om kontroll av information och databehandling, såväl som den hårdvara och mjukvara som krävs för detta. ERP-systemet representerar en komponent i en omfattande IT-arkitektur.
      Strategisk IT-hantering är oerhört värdefull för företagsledningen eftersom den direkt påverkar verksamhetens framgång genom att öka det värde som IT bidrar till företagets framgång, samtidigt som riskerna och kostnaderna för IT minimeras.`,
    },
    {
      question: "HR consultant",
      question_se: "HR-konsult",
      answer: `Human Resources is the core functional part of every organisation, as it ensures that all employees are both happy and protected. These training courses will help you to maintain effective employee and employer relationships by hy helping you to support and motivate employees. Each course is aimed at a different role within the HR department, ensuring employees have the correct skills to carry out their roles effectively. An effective HR department is able to quickly solve any problems which arise with employees, ensuring a productive and effective business environment which benefits all parties, and helping the business maintain compliance with HR laws.`,
      answer_se: `Mänskliga resurser är den centrala funktionella delen av varje organisation, eftersom det säkerställer att alla anställda är både glada och skyddade. Dessa utbildningar hjälper dig att upprätthålla effektiva anställda och arbetsgivarrelationer genom att hjälpa dig att stödja och motivera anställda. Varje kurs syftar till en annan roll inom HR-avdelningen, vilket säkerställer att anställda har rätt kompetens för att utföra sina roller effektivt. En effektiv HR-avdelning kan snabbt lösa alla problem som uppstår med de anställda, säkerställa en produktiv och effektiv affärsmiljö som gynnar alla parter och hjälpa verksamheten att upprätthålla efterlevnaden av HR-lagar.`,
    },
    {
      question: "Business development",
      question_se: "Affärsutveckling",
      answer: `There is one core responsibility of business development: growing your business. The strategies to operationalize and accomplish this goal will vary greatly across industries, but the idea is generally the same for all companies.`,
      answer_se: `Det finns ett huvudansvar för affärsutveckling: att växa ditt företag. Strategierna för att operativisera och uppnå detta mål kommer att variera mycket mellan olika branscher, men idén är i allmänhet densamma för alla företag.. Detta återspeglas i de räntor som erbjuds för lån och försäkringspremier. I många fall upptäcker inte kunderna hur dessa beräknas. Men det finns faktiskt ofta objektiva skäl som kan övervinnas. För att kunna hantera potentiella risker måste du vara medveten om dem så att du sedan kan hantera företaget på lång sikt med låga räntor och försäkringspremier`,
    },
    {
      question: "Budget control system",
      question_se: "Budgetkontrollsystem",
      answer: `In a budget control system, a firm assigns targets to each department, individual, etc. It then compares the budgeted performance with the actual one. The firm then reports the performance of each department to the top management. Hence, budget control serves as an effective tool for measuring the performance of departments, individuals and cost centres.
      The difference between budgeted performance and actual performance helps the management to identify its weak areas. The firm gives special attention to such areas`,
      answer_se: `I ett budgetkontrollsystem tilldelar ett företag mål till varje avdelning, individ osv. Det jämför sedan det budgeterade resultatet med det faktiska. Företaget rapporterar sedan resultatet för varje avdelning till den högsta ledningen. Följaktligen fungerar budgetkontroll som ett effektivt verktyg för att mäta prestanda hos avdelningar, individer och kostnadscentra.
      Skillnaden mellan budgeterade resultat och faktiska resultat hjälper ledningen att identifiera de svaga områdena. Företaget ägnar särskild uppmärksamhet åt sådana områden`,
    },
    {
      question: "cost reduction",
      question_se: "kostnadsminskning",
      answer: `In finer terms, cost reduction is a systematic and corrective technique used by most of the firms to cut the inessential expenses of the goods manufactured and increase the overall profits.
      In this process, the essential features and quality of the product are kept intact and is limited to the constant savings in the cost of production, administration, selling and distribution. The basic purpose is to lower down the cost occurring at the time of production, storing, selling etc.
      Cost Reduction is not related to fixing targets and standards, but it is about improving the standards. It is an ongoing process, which can be applied to all the activities of the concern. It focuses on the two primary areas:
      Reduction in Expenses: Decrease in the expenditure in the given volume of output, leads to the decrease in unit cost
      Increase in Productivity: The overall decrease in unit cost, by increase in the output, for the given expenditure.
      Cost reduction can be attained by the integration of these factors`,
      answer_se: `För att undvika risker eller åtminstone kunna motverka dem är det mycket viktigt att de erkänns, klassificeras och utvärderas. Riktade hierarkier, komplexa processer och IT-system och internationell integration gör riskhanteringskonsulttjänster nödvändiga för att säkerställa företagets framtid.
      Beroende på den juridiska formen är förvaltningen också juridiskt skyldig att driva ett fullt fungerande riskhanterings- och informationssystem.
      Kreditinstitut och försäkringsbolag bedömer också sina kunder utifrån icke-betalnings- och ansvarsrisker. Detta återspeglas i de räntor som erbjuds för lån och försäkringspremier. I många fall upptäcker inte kunderna hur dessa beräknas. Men det finns faktiskt ofta objektiva skäl som kan övervinnas. För att kunna hantera potentiella risker måste du vara medveten om dem så att du sedan kan hantera företaget på lång sikt med låga räntor och försäkringspremier`,
    },
    {
      question: "Leadership",
      question_se: "Ledarskap",
      answer: `In business, leadership is linked to performance, and any leadership definition has to take that into account. While it's not solely about profit, those who are viewed as effective leaders are the ones who increase their company's bottom lines. If an individual in a leadership role does not meet profit expectations set by boards, higher management, or shareholders, they may be terminated.1﻿
      The terms "leadership" and "management" tend to be used interchangeably. Management refers to a company's management structure as its leadership, or to individuals who are actually managers as the "leaders" of various management teams.
      Leadership, however, requires traits that extend beyond management duties. To be effective, a leader certainly has to manage the resources at their disposal. But leadership also involves communicating, inspiring and supervising`,
      answer_se: `När det gäller företag är ledarskap kopplat till prestanda och varje definition av ledarskap måste ta hänsyn till det. Även om det inte enbart handlar om vinst, är de som betraktas som effektiva ledare de som ökar sina företags resultat. Om en person i en ledarroll inte uppfyller vinstförväntningarna som fastställts av styrelser, högre ledning eller aktieägare kan de avslutas.1
      Begreppen "ledarskap" och "ledning" brukar användas omväxlande. Ledningen hänvisar till ett företags ledningsstruktur som dess ledarskap, eller till individer som faktiskt är chefer som "ledare" för olika ledningsgrupper.
      Ledarskap kräver emellertid drag som sträcker sig utöver ledningsuppgifter. För att vara effektiv måste en ledare verkligen hantera resurserna till sitt förfogande. Men ledarskap innebär också att kommunicera, inspirera och övervaka`,
    },
    {
      question: "Business Statistics",
      question_se: "Företagsstatistik",
      answer: `Business statistics takes the data analysis tools from elementary statistics and applies them to business. For example, estimating the probability of a defect coming off a factory line, or seeing where sales are headed in the future. Many of the tools used in business statistics are built on ones you’ve probably already come across in basic math: mean, mode and median, bar graphs and the bell curve, and basic probability. Hypothesis testing (where you test out an idea) and regression analysis (fitting data to an equation) builds on this foundation.`,
      answer_se: `Affärsstatistik tar dataanalysverktygen från elementär statistik och tillämpar dem på företag. Till exempel att uppskatta sannolikheten för att en defekt kommer från en fabrikslinje eller se varför försäljningen är på väg i framtiden. Många av verktygen som används i affärsstatistik bygger på verktyg du antagligen redan har stött på i grundläggande matematik: medelvärde, läge och median, stapeldiagram och klockkurvan och grundläggande sannolikhet. Hypotest (där du testar en idé) och regressionsanalys (anpassning av data till en ekvation) bygger på denna grund.`,
    },
    {
      question: "Organizational development",
      question_se: "Organisatorisk utveckling",
      answer: `Organizational development can be defined as an objective-based methodology used to initiate a change of systems in an entity. Organizational development is achieved through a shift in communication processes or their supporting structure. Studying the behavior of employees enables professionals to examine and observe the work environment and anticipate change, which is then effected to accomplish sound organizational development.
      Increasing productivity and efficiency comes with many benefits. One of the best ways to encourage positive results in these metrics is by using a well-thought-out organizational development structure. Organizational development is used to equip an organization with the right tools so that it can adapt and respond positively (profitably!) to changes in the market`,
      answer_se: `Organisationsutveckling kan definieras som en målbaserad metod som används för att initiera en förändring av system i ett företag. Organisationsutveckling uppnås genom en förändring i kommunikationsprocesser eller deras stödstruktur. Att studera anställdas beteende gör det möjligt för professionella att undersöka och observera arbetsmiljön och förutse förändringar, som sedan genomförs för att uppnå sund organisationsutveckling.
      Ökad produktivitet och effektivitet kommer med många fördelar. Ett av de bästa sätten att uppmuntra positiva resultat i dessa mätvärden är att använda en väl genomtänkt organisationsutvecklingsstruktur. Organisationsutveckling används för att utrusta en organisation med rätt verktyg så att den kan anpassa sig och reagera positivt (lönsamt!) På förändringar på marknaden`,
    },
    {
      question: "teambuilding",
      question_se: "teambuilding",
      answer: `Teambuilding is dedicated to developing innovative teambuilding programs, which help companies and teams of different sizes and backgrounds to work better together.
      Our added value is incorporating company values, strategy into teambuilding activities, which leave participants at a whole new level of experience and integration with their company. The results are very broad. Enriching business events with teambuilding activities contributes to an excellent atmosphere as well as to a higher level of networking of participants. For the companies that decide to systematically build their teams also by implementing such innovative approaches, the results are proven in better coherency and efficiency of their teams.`,
      answer_se: `Teambuilding ägnar sig åt att utveckla innovativa teambuilding-program, som hjälper företag och team av olika storlekar och bakgrunder att arbeta bättre.
      Vårt mervärde är att integrera företagsvärden, strategi i teambuilding-aktiviteter, som ger deltagarna en helt ny nivå av erfarenhet och integration med deras företag. Resultaten är mycket breda. Berikande affärsevenemang med teambuilding-aktiviteter bidrar till en utmärkt atmosfär samt till en högre nivå av deltagarnas nätverk. För de företag som beslutar att systematiskt bygga upp sina team också genom att genomföra sådana innovativa tillvägagångssätt bevisas resultaten i bättre sammanhållning och effektivitet hos deras team.`,
    },
    {
      question: "Work Enviroment",
      question_se: "Arbetsmiljö",
      answer_se: `Motivering har alltid varit en utmaning. Kunskaps- och färdighetsinlärningen i detta program är utformad för att utmana ledare att hitta strategier och åtgärder för att skapa en positiv arbetsmiljö för sig själva och de anställda i deras arbetsenheter.
      Att producera resultat baserat på företagens mål och mål kräver inte bara att de anställda vet vad som förväntas av dem, utan att de är engagerade och motiverade för att ge bästa möjliga resultat. I en positiv arbetsmiljö vill människor producera, vara effektiva och vara stolta över sina bidrag.`,
      answer: `Motivating has always been a challenge. The knowledge and skills learning in this program are designed to challenge leaders to find strategies and action to create a positive work environment for themselves and the employees of their work units.
      Producing results based on corporate goals and objectives requires not only that employees know what is expected of them, but that they are engaged and motivated to provide the best results possible. In a positive work environment, people want to produce, to be effective, and to be proud of their contributions.`,
    },
    {
      question: "International trade ",
      question_se: "Internationellt byte",
      answer_se: `Internationell handel är utbyte av kapital, varor och tjänster över internationella gränser eller territorier. I de flesta länder utgör sådan handel en betydande andel av bruttonationalprodukten. Medan internationell handel har funnits under mycket av historien, har dess ekonomiska, sociala och politiska betydelse ökat under senare århundraden. Industrialisering, avancerad transport, globalisering, multinationella företag och outsourcing har alla en stor inverkan på det internationella handelssystemet. Ökad internationell handel är avgörande för att globaliseringen ska fortsätta. Utan internationell handel skulle länder begränsas till de varor och tjänster som produceras inom sina egna gränser. Internationell handel skiljer sig i princip inte från inrikeshandel eftersom motivationen och beteendet hos parter som är involverade i en handel inte förändras i grunden oavsett om handeln är över en gräns eller inte. Den största skillnaden är att internationell handel vanligtvis är dyrare än inrikeshandeln. Anledningen är att en gräns vanligtvis lägger in extrakostnader såsom tullar, tidskostnader på grund av förseningar i gränserna och kostnader som är förknippade med landskillnader som språk, rättssystem eller kultur.`,
      answer: `International trade is the exchange of capital, goods, and services across international borders or territories. In most countries, such trade represents a significant share of gross domestic product. While international trade has been present throughout much of history, its economic, social, and political importance has been on the rise in recent centuries. Industrialization, advanced transportation, globalization, multinational corporations, and outsourcing are all having a major impact on the international trade system. Increasing international trade is crucial to the continuance of globalization. Without international trade, nations would be limited to the goods and services produced within their own borders. International trade is, in principle, not different from domestic trade as the motivation and the behavior of parties involved in a trade do not change fundamentally regardless of whether trade is across a border or not. The main difference is that international trade is typically more costly than domestic trade. The reason is that a border typically imposes additional costs such as tariffs, time costs due to border delays and costs associated with country differences such as language, the legal system or culture.`,
    },
    {
      question: "organizational development",
      question_se: "Organisatorisk utveckling",
      answer_se: `Den hänvisar till ett planerat och systematiskt tillvägagångssätt för att förbättra effektiviteten hos ett företag, regeringsavdelningen eller någon organisation - en som anpassar strategi, individer och processer. Det inkluderar praxis av planerad, systematisk förändring i värderingar, attityder och övertygelser för företagets anställda genom att skapa och förstärka utbildningsprogram på medellång eller lång sikt.
      Ett växande område inom Human Resource Management, organisationsutveckling är studien av framgångsrika organisationsprestanda och förändring. Ämnet kom fram på 1930-talet från studier av mänskliga relationer, där psykologer blev medvetna om att organisationsstrukturer och processer påverkar arbetskraftens motivation och beteende.
      OD i dag omfattar också anpassning av organisationer till deras komplexa och snabbt föränderliga miljöer genom organisatoriskt lärande, kunskapshantering och anpassning av organisationsvärden och normer.`,
      answer: `It refer to a planned and systematic approach to improving the effectiveness of a company, government department or any organization – one that aligns strategy, individuals and processes. It includes the practice of planned, systematic change in the values, attitudes and beliefs of a company’s employees through the creation and reinforcement of medium- or long-term training programs.
      A growing field of Human Resource Management, organizational development is the study of successful organizational performance and change. The subject emerged in the 1930s from human relations studies, during which psychologists became aware that organizational structures and processes influence the motivation and behavior of the workforce.
      OD today also encompasses the aligning of organizations with their complex and rapidly changing environments through organizational learning, knowledge management and adapting organizational values and norms.`,
    },
    {
      question: "project development",
      question_se: "projektutveckling",
      answer_se: `För dem som har fått uppgiften att hantera projekt - oavsett storlek - är oroet för att inte slutföra det ordentligt i tid, inom budgeten och faktiskt mycket riktigt.
      För att säkerställa att du slutför ett framgångsrikt projekt är nyckeln att vara organiserad och planera.
      Och som allting i livet verkar plötsligt en stor uppgift, uppdelad i en serie mindre uppgifter, inte så stor. Ett framgångsrikt genomförande av projektutvecklingen kan delas upp i fem projektutvecklingsfaser:
      Initiering
      Planera
      Avrättning
      Kontrollerande
      Stängning
      Genom att genomföra varje fas korrekt kan du vara säker på att du kommer att bli ett genomtänkt och framgångsrikt projekt.`,
      answer: `For those who have been given the task of managing projects — regardless of the size — the worry of not completing it properly on time, within budget, and effectively is a very real one.
      To make sure that you complete a successful project, the key is in being organized and planning.
      And like anything in life, a big task, broken down into a series of smaller tasks suddenly doesn’t seem so big. A successful implementation of project development can be separated into 5 project development phases:
      Initiation
      Planning
      Execution
      Controlling
      Closing
      By completing each phase properly, you can be sure that you will turn out a well thought-out and successful project.`,
    },
    {
      question: "Quality Assurance",
      question_se: "Kvalitetssäkring",
      answer_se: `Kvalitetssäkring (QA) är varje systematisk process för att avgöra om en produkt eller tjänst uppfyller specifika krav.
      QA fastställer och upprätthåller ställda krav för utveckling eller tillverkning av pålitliga produkter. Ett kvalitetssäkringssystem är avsett att öka kundernas förtroende och ett företags trovärdighet, samtidigt som man förbättrar arbetsprocesser och effektivitet, och gör det möjligt för ett företag att konkurrera bättre med andra.`,
      answer: `Quality assurance (QA) is any systematic process of determining whether a product or service meets specified requirements.
      QA establishes and maintains set requirements for developing or manufacturing reliable products. A quality assurance system is meant to increase customer confidence and a company's credibility, while also improving work processes and efficiency, and it enables a company to better compete with others.`,
    },
    {
      question: "Personnel planning",
      question_se: "Personalplanering",
      answer_se: `Personalplanering är en viktig utveckling inom personalförvaltningen. Det har spridit sig snabbt till nästan alla storlekar i alla typer av verksamheter. Den primära funktionen för personalplanering är att analysera och utvärdera de tillgängliga personalresurserna i organisationen och att bestämma hur man får de typer av personal som krävs för att anställa tjänster från montage linje arbetare till chefer. Mindre företag placerar personalplanering i personalavdelningen. Några av de största företagen har upprättat separata avdelningar för denna funktion`,
      answer: `Personnel planning is an important development in human resourcesmanagement. It has spread rapidly to nearly every size organization in almostevery kind of business.The primary function of Personnel planning is to analyze and evaluate the humanresources available in the organization, and to determine how to obtain the kinds of personnel needed to staff positions ranging from assembly line workers to chief executives. Smaller companies put Personnel planning in the human resource orpersonnel department. Some of the largest corporations have established separatedepartments for this function`,
    },
    {
      question: "Production management",
      question_se: "Produktionshantering",
      answer_se: `Produktionshantering är en dimension av företagsledningen. Den fokuserar på omvandlingsprocessen för insatsvaror och råvaror till företagets färdiga produkter, som skiljer sig från funktioner som är specialiserade på andra dimensioner som marknadsföring, försäljning, distribution, ekonomi och informationssystem.
      Produktionsledningen bör garantera ett framgångsrikt genomförande av företagets produktionsstrategi som involverar tillämpning av viss teknik och uppnådda förinställda mål relaterade till produktionsmixer, enhetskostnader, kvalitet och produktionskapacitet. Den koordinerar, övervakar och kontrollerar i allmänhet personer eller grupper som är ansvariga för själva produktionen, maskinunderhåll, kvalitetskontroll och lagerkontroll.
      Denna funktion bör också vara ansvarig för ofta förbättrade produktionsaktiviteter i syfte att göra dem mer effektiva. I vissa fall kan produktionshantering vara ansvarig för produktinnovation.`,
      answer: `Production management is one dimension of business management. It focuses on the transformation process of inputs and raw materials into the company’s finished products, which differs from functions specialized on other dimensions such as marketing, sales, distribution, finance and information systems.
      The production management should guarantee the successful implementation of the company’s production strategy which involves the application of certain technologies and achievements of pre-set goals related to production mixes, unit costs, quality and production capacity. It generally coordinates, supervises and controls persons or groups in command of production itself, machinery maintenance, quality control and inventory control.
      This function should also be responsible for frequent improvement of production activities with the aim of making them more efficient. In some cases, production management could be responsible for product innovation.`,
    },
    {
      question: "Project management",
      question_se: "Projektledning",
      answer_se: `Projektledning är tillämpning av processer, metoder, färdigheter, kunskap och erfarenhet för att uppnå specifika projektmål enligt projektets godkännande kriterier inom överenskomna parametrar. Projektledningen har slutliga leveranser som är begränsade till en begränsad tidsplan och budget.
      En nyckelfaktor som skiljer projektledningen från bara "management" är att den har denna slutliga leverans och en begränsad tidsperiod, till skillnad från management som är en pågående process. På grund av detta behöver en projektperson professionell ett brett spektrum av färdigheter; ofta tekniska färdigheter, och säkert människors ledningsförmåga och god affärsmedvetenhet.`,
      answer: `Production management is one dimension of business management. It focuses on the transformation process of inputs and raw materials into the company’s finished products, which differs from functions specialized on other dimensions such as marketing, sales, distribution, finance and information systems.
      The production management should guarantee the successful implementation of the company’s production strategy which involves the application of certain technologies and achievements of pre-set goals related to production mixes, unit costs, quality and production capacity. It generally coordinates, supervises and controls persons or groups in command of production itself, machinery maintenance, quality control and inventory control.
      This function should also be responsible for frequent improvement of production activities with the aim of making them more efficient. In some cases, production management could be responsible for product innovation.`,
    },
    {
      question: "marketing, marketing strategy",
      question_se: "marknadsföring, marknadsföringsstrategi",
      answer_se: `Att utveckla en marknadsföringsstrategi är avgörande för alla företag. Utan en kommer dina ansträngningar att locka kunder troligen att vara slumpmässiga och ineffektiva.
      Fokus för din strategi bör vara att se till att dina produkter och tjänster möter kundernas behov och utveckla långsiktiga och lönsamma relationer med dessa kunder. För att uppnå detta måste du skapa en flexibel strategi som kan svara på förändringar i kundernas uppfattning och efterfrågan. Det kan också hjälpa dig att identifiera helt nya marknader som du framgångsrikt kan rikta in dig på.
      Syftet med din marknadsföringsstrategi bör vara att identifiera och sedan kommunicera fördelarna med ditt affärserbjudande till din målmarknad.
      När du har skapat och implementerat din strategi, övervaka dess effektivitet och göra alla justeringar som krävs för att upprätthålla dess framgång.`,
      answer: `Project management is the application of processes, methods, skills, knowledge and experience to achieve specific project objectives according to the project acceptance criteria within agreed parameters. Project management has final deliverables that are constrained to a finite timescale and budget.
         A key factor that distinguishes project management from just 'management' is that it has this final deliverable and a finite timespan, unlike management which is an ongoing process. Because of this a project professional needs a wide range of skills; often technical skills, and certainly people management skills and good business awareness.`,
    },
    {
      question: "Valuation of companies",
      question_se: "Värdering av företag",
      answer_se: `En företagsvärdering är ett sätt att bestämma det ekonomiska värdet på ett företag, vilket kan vara användbart i flera situationer. Till exempel:
      Du kan behöva sälja verksamheten på grund av pension, hälsa eller av familjeskäl.
      Du kan behöva skuld- eller kapitalfinansiering för expansion eller på grund av kassaflödesproblem, i vilket fall potentiella investerare kommer att vilja se att verksamheten har tillräckligt värde.
      Du kan lägga till aktieägare (eller en eller flera aktieägare kan begära ett utköp). I detta fall måste aktievärdet bestämmas.
      Oavsett anledning, att genomföra en affärsvärdering hjälper dig att ställa in ett lämpligt pris för försäljningen av verksamheten.`,
      answer: `A business valuation is a way to determine the economic value of a company, which could be useful in several situations. For example:
      You may need to sell the business due to retirement, health or for family reasons.
      You may need debt or equity financing for expansion or due to cash flow problems, in which case potential investors will want to see that the business has sufficient worth.
      You may be adding shareholders (or one or more shareholders may ask for a buyout). In this case, the share value will need to be determined.
      Whatever the reason, performing a business valuation will help you set an appropriate price for the sale of the business.`,
    },
    {
      question: "Problem Solving Methods",
      question_se: "Problemlösningsmetoder",
      answer_se: `problemlösning är processen för att hitta lösningar på svåra eller komplexa problem. Men det visste du redan. Förståelse av problemlösningsramar kräver emellertid ett djupare dyk.
      Tänk på ett problem som du nyligen har mött. Det var kanske en interpersonlig fråga. Eller det kunde ha varit en stor kreativ utmaning du behövde lösa för en klient på jobbet. Hur kände du dig när du närmade dig frågan? Stressad? Förvirrad? Optimistisk? Viktigast av allt, vilka problemlösningstekniker använde du för att ta itu med situationen direkt? Hur organiserade du tankar för att komma fram till bästa möjliga lösning?
      Svaren på dessa frågor säger mycket om dina kunskaper om problemlösning. Men om du har problem med att veta det bästa sättet att ta till sig ett problem, är du inte ensam.`,
      answer: ` problem solving is the process of finding solutions to difficult or complex issues. But you already knew that. Understanding problem solving frameworks, however, requires a deeper dive.
      Think about a recent problem you faced. Maybe it was an interpersonal issue. Or it could have been a major creative challenge you needed to solve for a client at work. How did you feel as you approached the issue? Stressed? Confused? Optimistic? Most importantly, which problem solving techniques did you use to tackle the situation head-on? How did you organize thoughts to arrive at the best possible solution?
      The answers to these questions say a lot about your problem solving approach skills. But if you have trouble knowing the best way to approach a problem, you’re not alone.`,
    },
  ];

  if (!faqs || faqs.length === 0) faqs = faqs1;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  const HeadingBase = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`;
  const SectionHeading = tw(HeadingBase)`text-primary-900 py-5 lg:py-5`;

  return (
    <Container>
      <Content>
        <SectionHeading style={{ color: "#666666" }}>
          {lang === "se"
            ? "Vår professionellatjänster"
            : "Our Professional Services"}
        </SectionHeading>
        <TwoColumn>
          <Column tw="hidden lg:block w-7/12 flex-shrink-0">
            <img src={imageSrc} />
          </Column>
          <Column>
            <FAQContent>
              <FAQSContainer>
                {faqs.slice(0, 6).map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText style={{ color: "#666666" }}>
                        {lang === "se" ? faq.question_se : faq.question}
                      </QuestionText>
                      <QuestionToggleIcon
                        variants={{
                          collapsed: { rotate: 0 },
                          open: { rotate: -180 },
                        }}
                        initial="collapsed"
                        animate={
                          activeQuestionIndex === index ? "open" : "collapsed"
                        }
                        transition={{
                          duration: 0.02,
                          ease: [0.04, 0.62, 0.23, 0.98],
                        }}
                      >
                        <ChevronDownIcon />
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      style={{ color: "#666666" }}
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                      }}
                      initial="collapsed"
                      animate={
                        activeQuestionIndex === index ? "open" : "collapsed"
                      }
                      transition={{
                        duration: 0.3,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      {lang === "se" ? faq.answer_se : faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
      <Content>
        <TwoColumn>
          <Column>
            <FAQContent>
              <FAQSContainer>
                {faqs.slice(7, 12).map((faq, index) => (
                  <FAQ
                    key={index + 6}
                    onClick={() => {
                      toggleQuestion(index + 6);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText style={{ color: "#666666" }}>
                        {lang === "se" ? faq.question_se : faq.question}
                      </QuestionText>
                      <QuestionToggleIcon
                        variants={{
                          collapsed: { rotate: 0 },
                          open: { rotate: -180 },
                        }}
                        initial="collapsed"
                        animate={
                          activeQuestionIndex === index + 6
                            ? "open"
                            : "collapsed"
                        }
                        transition={{
                          duration: 0.02,
                          ease: [0.04, 0.62, 0.23, 0.98],
                        }}
                      >
                        <ChevronDownIcon />
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      style={{ color: "#666666" }}
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                      }}
                      initial="collapsed"
                      animate={
                        activeQuestionIndex === index + 6 ? "open" : "collapsed"
                      }
                      transition={{
                        duration: 0.3,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      {lang === "se" ? faq.answer_se : faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
          <Column tw="hidden lg:block w-7/12 flex-shrink-0">
            <img src="http://fhmetal.se/image/6534.jpg" />
          </Column>
        </TwoColumn>
      </Content>
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-7/12 flex-shrink-0">
            <img src="http://fhmetal.se/image/5867.jpg" />
          </Column>
          <Column>
            <FAQContent>
              <FAQSContainer>
                {faqs.slice(13, 18).map((faq, index) => (
                  <FAQ
                    onClick={() => {
                      toggleQuestion(index + 11);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText style={{ color: "#666666" }}>
                        {lang === "se" ? faq.question_se : faq.question}
                      </QuestionText>
                      <QuestionToggleIcon
                        variants={{
                          collapsed: { rotate: 0 },
                          open: { rotate: -180 },
                        }}
                        initial="collapsed"
                        animate={
                          activeQuestionIndex === index + 11
                            ? "open"
                            : "collapsed"
                        }
                        transition={{
                          duration: 0.02,
                          ease: [0.04, 0.62, 0.23, 0.98],
                        }}
                      >
                        <ChevronDownIcon />
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      style={{ color: "#666666" }}
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                      }}
                      initial="collapsed"
                      animate={
                        activeQuestionIndex === index + 11
                          ? "open"
                          : "collapsed"
                      }
                      transition={{
                        duration: 0.3,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      {lang === "se" ? faq.answer_se : faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
      <Content>
        <TwoColumn>
          <Column>
            <FAQContent>
              <FAQSContainer>
                {faqs.slice(19, 26).map((faq, index) => (
                  <FAQ
                    key={index + 16}
                    onClick={() => {
                      toggleQuestion(index + 16);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText style={{ color: "#666666" }}>
                        {lang === "se" ? faq.question_se : faq.question}
                      </QuestionText>
                      <QuestionToggleIcon
                        variants={{
                          collapsed: { rotate: 0 },
                          open: { rotate: -180 },
                        }}
                        initial="collapsed"
                        animate={
                          activeQuestionIndex === index + 16
                            ? "open"
                            : "collapsed"
                        }
                        transition={{
                          duration: 0.02,
                          ease: [0.04, 0.62, 0.23, 0.98],
                        }}
                      >
                        <ChevronDownIcon />
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      style={{ color: "#666666" }}
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                      }}
                      initial="collapsed"
                      animate={
                        activeQuestionIndex === index + 16
                          ? "open"
                          : "collapsed"
                      }
                      transition={{
                        duration: 0.3,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      {lang === "se" ? faq.answer_se : faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
          <Column tw="hidden lg:block w-7/12 flex-shrink-0">
            <img src="http://fhmetal.se/image/5278.jpg" />
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
